import * as React from 'react'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import FooterMenu from '../menu/FooterMenu'
import Image from 'next/image'
import Logo from '../../public/images/logoFooter.svg'

const FooterWrap = styled(Box)(({ theme }) => ({
  background: theme.palette.footer.main,
  color: theme.palette.footer.contrastText,
  paddingTop: 40,
  paddingBottom: 40,
  marginTop: 'auto',
  position: 'relative'
}))

export default function Footer () {
  return (
    <FooterWrap>
      <Box sx={{ textAlign: 'center', mb: 5 }}>
        <Image alt='Altis' src={Logo} layout='fixed' width={34} height={29} />
      </Box>
      <FooterMenu />
      <Box sx={{ mt: 6, mb: 2, textAlign: 'center', px: 2 }}>
        © {new Date().getFullYear()}{' '}ALTIS MOVEMENT TECHNOLOGIES, INC. | All Rights Reserved
      </Box>
    </FooterWrap>
  )
}