import * as React from 'react'
import NavLink from 'next/link'
import { Link } from '@mui/material'
import Image from 'next/image'
import LogoAltis from '../../../public/images/logoHeaderMobile.svg'

export default function Logo() {
  return (
    <>
      <NavLink passhref='true' href='/'>
        <Link sx={{ cursor: 'pointer', lineHeight: 1 }}>
          <Image
            priority
            alt='Altis'
            src={LogoAltis}
            layout='fixed'
            width={34}
            height={34}
            sx={{ cursor: 'pointer' }}
          />
        </Link>
      </NavLink>
    </>
  )
}