import { styled } from '@mui/system'
import Link from '../../common/Link'

const HeaderMenuItem = styled(Link)(({ theme }) => ({
  color: theme.palette.footer.contrastText,
  cursor: 'pointer',
  margin: '0 15px',
  textDecoration: 'none',
  fontWeight: 600,
  position: 'relative',
  transition: 'all .2s ease-in-out',
  '&:hover': {
    textDecoration: 'none !important',
    textDecorationColor: 'none',
    color: '#fff',
    '&:before': {
      width: '100%'
    }
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: '-5px',
    width: 0,
    height: 2,
    transform: 'translateZ(0)',
    transition: 'width 0.2s ease',
    backgroundColor: '#fff'
  }
}))

export default HeaderMenuItem