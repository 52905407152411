import { styled } from '@mui/system'
import Link from './Link'
import { motion, AnimatePresence } from 'framer-motion'

const ReserveOrder = styled(Link)(() => ({
  color: '#fff',
  borderColor: '#219CE1',
  backgroundColor: '#219CE1',
  width: '100%',
  minWidth: '264px',
  maxWidth: '264px',
  padding: '16px 46px',
  borderRadius: 38,
  fontSize: '1.125rem',
  lineHeight: '1.38889',
  display: 'inline-block',
  textAlign: 'center',
  textDecoration: 'none',
  fontWeight: 900,
  '&:hover': {
    backgroundColor: '#047abc',
  },
}))

export const ReserveInviteButton = (props) => {
  return (
    <ReserveOrder
      {...props}
      href='https://www.indiegogo.com/projects/ai-personal-trainer-powered-by-movementos/'
      passhref='true'
    >
      Shop Altis
    </ReserveOrder>
  )
}

export const HeaderReserveInviteButton = ({ isVisible }) => {
  if (isVisible === null) isVisible = true
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key='headerButton'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0 }}
        >
          <ReserveInviteButton
            sx={{
              padding: '10px 16px',
              minWidth: 0,
              margin: 0,
              fontWeight: 700,
              fontSize: '1rem',
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
